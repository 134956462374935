import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenPositionAdminService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private http: HttpClient, private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  uploadResume(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    let path = `${this.baseUrl}/files/upload`;
    return this.apiservice.post(path, formData);
  }

  getResume(resumePath: string): Observable<Blob> {
    const apiUrl = `${this.baseUrl}/files/${resumePath}`;
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  getCandidateList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    positionID: string,
    status?: string,
    noticePeriod?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/candidate/list?positionID=${positionID}&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (status) {
      path += `&status=${status}`;
    }

    if (noticePeriod) {
      path += `&noticePeriodRanges=${noticePeriod}`;
    }

    return this.apiservice.get(path);
  }

  addCandidate(formData: any): Observable<any> {
    let path = `${this.baseUrl}/candidate`;
    return this.apiservice.post(path, formData);
  }

  updateCandidate(PositionData: any): Observable<any> {
    const url = `${this.baseUrl}/candidate`;
    return this.apiservice.put(url, PositionData).pipe(
      catchError((error: any) => {
        console.error('Error updating Candidate:', error);
        throw error;
      })
    );
  }
}
